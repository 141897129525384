@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;600;700&family=Raleway&display=swap");

@tailwind base;
@tailwind components;

:root {
  --text-primary: #3a3a3a;
  --btn-primary-blue: #283891;
  --btn-hover-primary-blue: #1c2766;
  --primary-gray: #e5eaf0;
  --sec-gray: #848f99;
  --radius-main: 15px;
}

body {
  font-family: "Lato", sans-serif;
  color: var(--text-primary);
  line-height: 20px;
}

h1 {
  @apply text-[34px] leading-[42px] font-bold;
}

h2 {
  @apply text-[28px] leading-[34px] font-bold;
}

h3 {
  @apply text-2xl leading-[30px] font-bold;
}

.pl-bold {
  @apply leading-5 font-bold;
}

.pl-reg {
  @apply leading-5;
}

.ps-bold {
  @apply text-sm leading-[18px] font-bold;
}

.ps-reg {
  @apply text-sm leading-[18px];
}

.cap-bold {
  @apply text-xs font-bold;
}

.cap-reg {
  @apply text-xs;
}

input,
textarea,
select {
  @apply px-4 py-2 rounded border border-[--sec-gray] hover:border-[--text-primary] focus-visible:border-[--btn-primary-blue] outline-0;
}

input[type="date"],
select {
  @apply h-[42px]
}

.center {
  @apply xl:w-[1440px] xl:mx-auto;
}

.login-sigup-bgImg {
  background-image: url("./assets/ims-bg.png");
  background-size: cover;
  background-position: center;
  z-index: 1;
  position: relative;
}

.login-sigup-bgImg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #afb3b899;
  z-index: -1;
}

input,
select,
textarea,
label {
  cursor: text;
}

.btn {
  @apply text-xs lg:text-base leading-5 font-bold w-36 h-10 text-center content-center rounded disabled:bg-[--primary-gray] disabled:text-[#AFB3B8] disabled:border-0;
}

.btn-blue {
  @apply bg-[--btn-primary-blue] text-white hover:bg-[--btn-hover-primary-blue]
}

.btn-outline {
  @apply text-[--btn-primary-blue] border border-[--btn-primary-blue] hover:bg-[#28389118];
}

.btn-white {
  @apply bg-white max-lg:w-full btn-outline;
}

thead {
  @apply sticky top-0 bg-[--primary-gray];
}

tbody tr:nth-child(even) {
  @apply bg-[--primary-gray];
}

.nav-shadow {
  box-shadow: 0 2px 10px #0000001a;
}

/* 0 - 1023 */
@media screen and (max-width: 1024px) {
  .h-sm-balance-inquiry {
    max-height: calc(100dvh - 586px);
  }

  .h-sm-form {
    height: calc(100dvh - 247px);
  }
}

/* 1024 - larger */
@media screen and (min-width: 1024px) {
  .h-sm-form {
    max-height: calc(100vh - 283px);
    overflow-y: auto;
  }
}




/* Navbar active routes */
.half-underline {
  @apply relative text-[--btn-primary-blue] max-lg:pb-2.5 after:content-[''] after:absolute after:left-0 after:bottom-0 lg:after:-bottom-2.5 after:h-[3px] after:w-1/2 after:bg-[--btn-primary-blue];
}




/*/ Loaders /*/

/* Spinner */
.loader {
  position: relative;
  top: 5px;
  width: 24px;
  height: 24px;
  border: 3px solid var(--text-primary);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


/* Content Loader */
.content-loader {
  /* background: linear-gradient(0.25turn, transparent, #FFF, transparent), linear-gradient(#DDD, #DDD); */
  background-color: var(--primary-gray);
  background-repeat: no-repeat;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    /* background-position: 215px 0, 0 0, 15px 150px, 15px 180px; */
    opacity: 1;
  }
}

@tailwind utilities;